<template>
  <div class="vista-escritorio">
    <Cabecera />
    <Cuerpo v-if="cargaTerminada">
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>
              {{ eleccion }} -
              <span>
                <strong>Detalle del Distrito</strong> - Detalle por Casilla
              </span>
            </h2>
            <h4>
              <strong>
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`" class="estado-volver">
                  Baja California Sur
                </router-link>
              </strong> /
              <span v-if="this.$route.params.eleccion !== 'A'">
                Distrito {{distrito}}. {{ distrito_nombre }}
              </span>
              <span v-else>
                Municipio {{this.distrito}}. {{ ayuntamiento.municipio_descripcion }}
              </span>
            </h4>

            <p>
              El total de votos mostrado a nivel Entidad representa la suma del
              voto emitido en territorio Estatal y en el Extranjero. <br/> Por
              presentación, los decimales de los porcentajes muestran sólo cuatro
              dígitos. No obstante, al considerar todos los decimales, suman 100%.
            </p>
            <h3>Votos en Actas Contabilizadas</h3>

            <slider-votos :votos="votosXCandidatoDistritoTarjeta.datos_candidaturas" />

            <div class="row px-3">
              <div class="col-8 mb-3 resumen-votacion">
                <h4 class="mb-2">
                  Resumen de la votación
                </h4>
                <div class="grilla-suma">
                  <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos acumulados
                      <span id="tooltip">
                        <font-awesome-icon icon="info-circle" class="fa-sm fa-info" />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Coalición <br />
                          o Candidatura Independiente.
                        </span>
                      </span>
                      <br />
                      {{ resumenDistrito.total_votos_acumulados_numero}} <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_acumulados_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      {{ resumenDistrito.total_votos_candidatura_noregistrada_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_candidatura_noregistrada_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                      Nulos <br />
                      {{ resumenDistrito.total_votos_nulos_numero }} <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_nulos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right">
                    <p class="font-weight-bold">
                      Total de votos <br />
                      <big>{{ resumenDistrito.total_votos_general_numero }}</big> <br />
                      <span class="porcentaje">
                        {{ resumenDistrito.total_votos_general_porcentaje }}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="votos detalle-por-casilla">
        <div class="votos-cabecera">
          <div class="titulos">
            <h4>
              Detalle de votos por Casilla
            </h4>
            <procedencia-acta />

            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="text-center">
                    <th
                      v-for="(candidatura, index) in votosXCasilla.datos_nombres_columnas"
                      :key="index"
                    >
                      <img
                        v-if="candidatura.candidatura_logo != 'sin logo'"
                        :src="candidatura.candidatura_logo"
                        :alt="candidatura.candidatura_nombre"
                      >
                      <div v-else>{{candidatura.candidatura_nombre}}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(casilla, index) in votosXCasilla.datos_casillas"
                    :key="index"
                  >
                    <td>
                      <router-link
                        :to="`/${$route.params.eleccion}/Secciones/${distrito}/${casilla.seccion_id}/${casilla.casilla_tipo}`"
                        class="font-weight-bold"
                      >
                        {{ casilla.seccion_id }} {{casilla.casilla_tipo}}
                      </router-link>
                    </td>
                    <td class="procedencias">
                      <a
                        :href="calcularUrlActaDigitalizada(casilla.datos_votos[1])"
                        target="_blank"
                      >
                        <nivel-procedencia
                          :procedencia="parseInt(casilla.datos_votos[1].candidatura_logo, 10)"
                        />
                      </a>
                    </td>
                    <td
                      v-for="(candidatura, index) in casilla.datos_votos.slice(2)"
                      :key="index"
                    >
                      {{candidatura.candidatura_votos_cantidad}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="votos participacion-ciudadana">
        <div class="votos-cabecera">
          <div class="titulos">
            <h4 class="mb-5">
              <u>
                Participación Ciudadana con base en la Lista
                Nominal de las Actas PREP Contabilizadas
              </u>
            </h4>

            <div class="table-responsive">
              <table class="table striped">
                <thead>
                  <tr class="text-center">
                    <th>Casilla</th>
                    <th>Participación ciudadana</th>
                    <th>Total de la Lista Nominal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-center"
                    v-for="(casilla, index) in participacionCiudadana.datos_casillas"
                    :key="index"
                  >
                    <td>
                      {{ casilla.seccion_id }} {{casilla.casilla_tipo}}
                    </td>
                    <td>
                      {{ casilla.lista_nominal_cantidad_porcentaje }}%
                    </td>
                    <td>
                      {{ casilla.lista_nominal_cantidad }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <ObservacionesActas />
          </div>
        </div>
      </div>

      <div class="text-center mt-3">
        <p>
          Los resultados presentados tienen un carácter informativo y no
          son definitivos, por tanto, no tienen efectos jurídicos. <br />
          Con base en la Ley Federal del Derecho de Autor queda prohíbida
          cualquier modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido
          total o parcial de este sitio, es delito federal de acuerdo al Código Penal Federal.
        </p>
      </div>
    </Cuerpo>
    <Compartir />
    <PieDePagina />
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable vue/return-in-computed-property */

import { defineComponent } from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import Cabecera from '@/components/Escritorio/componentes/Cabecera.vue';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo.vue';
import Compartir from '@/components/Compartir.vue';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas';
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue';
import ProcedenciaActa from '@/components/ProcedenciaActa.vue';
import NivelProcedencia from '@/components/NivelProcedencia.vue';
import SliderVotos from '@/components/Escritorio/componentes/SliderVotos.vue';

export default defineComponent({
  name: 'DetallePorCasilla',
  components: {
    Cabecera,
    Cuerpo,
    Compartir,
    ObservacionesActas,
    PieDePagina,
    ProcedenciaActa,
    NivelProcedencia,
    SliderVotos,
  },
  computed: {
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[this.distrito - 1];
    },
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find((d) => d.id == this.distrito).descripcion;
    },
    eleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }
      return null;
    },
    distrito() {
      return this.$route.params.distrito;
    },
    detalle() {
      /**
       * Aqui debemos de llamar al distrito correspondiente acorde a
       * las variables "Entidad" y "Distrito",
       * por lo pronto quedará estático con lo que tenemos en el JSON que nos dio el INE
       */

      // return this.$store.state.distritos.uno
      return this.$store.state.secciones.uno;
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
    votosXCandidatoDistritoTarjeta() {
      const { eleccion } = this.$route.params;
      if (eleccion === 'G') {
        return this.$store.state.gVotosXPartidosNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (eleccion === 'D') {
        return this.$store.state.dVotosXPartidosNivelDistrito.distritos[this.$route.params.distrito - 1];
      } if (eleccion === 'A') {
        return this.$store.state.aVotosXPartidosNivelDistrito.distritos[this.$route.params.distrito - 1];
      }
      return null;
    },
    resumenDistrito() {
      // Enviar el índice del Distrito
      return this.$store.state.gResumenNivelDistrito.distritos[this.$route.params.distrito - 1];
    },
    votosXCasilla() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXCasillaNivelDistrito.datos_distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXCasillaNivelDistrito.datos_distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXCasillaNivelDistrito.datos_distritos[this.$route.params.distrito - 1];
      }
      return null;
    },
    participacionCiudadana() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gParticipacionCiudadanaNivelDistrito.datos_distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dParticipacionCiudadanaNivelDistrito.datos_distritos[this.$route.params.distrito - 1];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aParticipacionCiudadanaNivelDistrito.datos_distritos[this.$route.params.distrito - 1];
      }
      return null;
    },
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Detalle por Casilla',
  },
  methods: {
    seleccionarCandidato(index) {
      // console.log(this.$store.state.distritos.uno.votosCandidatura[index])
      this.$store.state.distritos.uno.votosCandidatura[index].seleccionado = !this.$store.state.distritos.uno.votosCandidatura[index].seleccionado;
    },
    obtenerClaseGanador(candidato) {
      return candidato.ganador
        ? 'resumen-voto-candidatura'
        : 'resumen-voto-candidatura';
    },
  },
  created() {
    if (this.anchoPantalla < 992) {
      this.$router.push(`/${this.$route.params.eleccion}/DPC/${this.distrito}`);
    }
  },
  watch: {
    anchoPantalla(val) {
      // console.log(val)
      if (val < 992) {
        this.$router.push(`/${this.$route.params.eleccion}/DPC/${this.distrito}`);
      }
    },
  },
  setup () {
    return {
      ...useCalcularUrlActaEscaneada(),
    };
  },
});
</script>

<style lang="scss" scoped>
.resumen-voto-candidatura {
  padding: 3px;
  border: 1px solid #181A2F;
  //height: 25vh;
  max-width: 150px;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    margin-top:10px;
  }
}

.ganador {
  border-color: #D2127E;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #D2127E;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #181A2F;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#181A2F, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #34344E;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #D2127E;
      }
    }
  }
}

.tabla-distribucion-votos {
  tbody {
    tr {
      vertical-align: top;
      td {
        svg {
          max-width: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.detalle-por-casilla {
  table {
    thead {
      tr {
        background-color: #181A2F;
        color: #FFF;

        th {
          vertical-align: middle;
          img {
            max-height: 35px;
          }
        }
      }
    }
  }
}

.participacion-ciudadana {
  table {
    thead {
      background-color: #DDDDDD;
    }
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #F2EFEF;
        }
      }
    }
  }
}
</style>
